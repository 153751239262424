import Auth from 'Base/classes/Auth';
import Api from 'Base/classes/Api';
import Errors from 'Base/classes/Errors';

window.RatingModal = (url, subRatings) => {
    return {
        url: url,
        subRatings: subRatings,
        opened: false,
        step: 'rating',
        successMessage: '',
        form: null,
        user: null,
        init()
        {
            this.form = {
                rating: {
                    phone_verified: true,
                    text: '',
                    subRatings: [],
                    errors: new Errors(),
                },
                email: {
                    email: '',
                    errors: new Errors(),
                },
                login: {
                    email: '',
                    password: '',
                    errors: new Errors(),
                },
                register: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    password: '',
                    errors: new Errors(),
                },
                phone: {
                    number: '',
                    token: '',
                    smsSent: false,
                    errors: new Errors(),
                },
                loading: false
            };
            for ( let i = 0; i < this.subRatings.length; i++ ) {
                this.form.rating.subRatings.push({id: this.subRatings[i], value: null});
            }
            this.step = 'text';
            this.successMessage = '';
        },
        open() 
        {
            this.init();
            this.opened = true;
        },
        close()
        {
            this.user = null;
            this.opened = false;
        },
        submit()
        {
            if ( this.form.loading ) {
                return;
            }

            this.form.loading = true;

            if ( this.step == 'text' ) {
                this.form.rating.errors.clear();

                if ( this.form.rating.text == '' ) {
                    this.form.rating.errors.record({message: ['Vous devez décrire votre expérience avec ce professionnel pour continuer.']});
                    this.form.loading = false;
                    return;
                }

                this.step = 'ratings';
                this.form.loading = false;
                return;
            }

            if ( this.step == 'ratings' ) {
                this.form.rating.errors.clear();

                if ( ! this.allSubRatingsAreSelected() ) {
                    this.form.rating.errors.record({message: ['Vous devez selectionné une note pour chaque critère.']});
                    this.form.loading = false;
                    return;
                }

                if ( window.current.user ) {
                    return this.authed();
                }

                this.step = 'email';
                this.form.loading = false;
                return;
            }

            if ( window.current.user ) {
                this.authed();
            }
            
            
            new Auth().checkEmail(this.form.email.email)
                .then(({exists}) => {
                    this.form.login.email = this.form.register.email = this.form.email.email;
                    this.step = exists ? 'login' : 'register';
                    this.form.loading = false;
                });
        },
        success(message)
        {
            this.form.loading = false;
            this.successMessage = message;
            this.step = 'success';

            setTimeout(() => {
                this.close();
            }, 6000);
        },
        fail(errors)
        {
            this.form[this.step].errors.record(errors);
            this.form.loading = false
        },
        getData(step = this.step)
        {
            return this.form[step];
        },
        setSubRating(id, value)
        {
            for ( let i = 0; i < this.form.rating.subRatings.length; i++ ) {
                if ( this.form.rating.subRatings[i].id == id ) {
                    this.form.rating.subRatings[i].value = value;
                    return;
                }
            }

            this.form.rating.subRatings.push({id, value});
        },
        subRatingStarClass(id, value)
        {
            for ( let i = 0; i < this.form.rating.subRatings.length; i++ ) {
                if ( this.form.rating.subRatings[i].id == id && this.form.rating.subRatings[i].value >= value ) {
                    return `star--full`;
                }
            }

            return `star--empty`;
        },
        allSubRatingsAreSelected()
        {
            for ( let i = 0; i < this.form.rating.subRatings.length; i++ ) {
                if ( this.form.rating.subRatings[i].value == null ) {
                    return false;
                }
            }
            return true;
        },
        validate()
        {
            return new Api().post(`${this.url}/validate`, this.getData());
        },
        store()
        {
            new Api().post(this.url, this.getData('rating'))
                .then((response) => this.success(response.message));
        },
        login()
        {
            this.form.loading = true;

            new Auth().login(this.form.login)
                .then(response => {
                    this.authed(response.user);
                })
                .catch(({data}) => this.fail(data.errors));
        },
        register()
        {
            this.form.loading = true;

            new Auth().register(this.form.register)
                .then(response => {
                    this.authed(response.user);
                })
                .catch(({data}) => this.fail(data.errors));
        },
        authed(user = window.current.user)
        {            
            this.user = user;

            if ( this.user.is_confirmed_by_phone ) {
                return this.store();
            }

            this.form.loading = false;

            if ( ! this.user.phone ) {
                return this.step = 'set-phone';
            }
            
            this.step = 'confirm-phone';
        },
        addPhoneNumber()
        {
            this.form.loading = true;

            new Auth().addPhoneNumber(this.form.phone.number)
                .then((response) => {
                    this.user = window.current.user = response.user;
                    this.step = 'confirm-phone';
                    this.sendSMS();
                })
        },
        sendSMS()
        {
            this.form.loading = true;

            new Auth().sendConfirmationSMS()
                .then(() => {
                    this.form.phone.smsSent = true;
                    this.form.loading = false;
                })
        },
        confirmPhoneNumber()
        {
            this.form.loading = true;

            new Auth().confirmPhoneNumber(this.form.phone.token)
                .then(() => {
                    this.store();
                })
                .catch(error => {
                    this.form.phone.errors.record({token: error.data.message})
                    this.form.loading = false;
                });
        }
    }

}